body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/* font-family: 'Montserrat', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  overflow-x: hidden;
}

body {
  background: linear-gradient(107.56deg, #4cc8c8 0%, #202033 100%);
  font-family: 'Raleway', sans-serif;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* CUSTOM SCROLLBAR THEME  */
/* body::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #00cec3;
} */

/* navbar css */

p {
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h2 {
  padding: 0;
  margin: 0;
}

header .pasteezLogo {
  font-weight: 800;
  font-size: 26px;
  color: #edf0f1;
}

li,
a {
  font-weight: 500;
  font-size: 16px;
  color: #edf0f1;
  text-decoration: none;
}

header {
  font-size: 2em;
  font-weight: 800;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10%;
  height: 14vh;
}

.navlinks {
  list-style: none;
  margin: 0;
  display: flex;
  align-items: center;
}

.navlinks li {
  display: inline-block;
  padding: 0px 20px;
}

.navlinks li a {
  font-weight: 600;
  transition: all 0.3s ease 0s;
}

.navlinks li a:hover {
  color: #0088a9;
}

#navbar-profile-icon:hover {
  transform: scale(1.15);
}

/* footer css */

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7em;
  text-align: center;
  height: 16vh;
}

.footer-content {
  color: white;
}

.hamburger {
  display: none;
}
