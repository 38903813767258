.container {
  height: 71%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container {
  border-radius: 10px;
  padding: 20px;
  color: white;
  width: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.home-heading {
  font-size: 4em;
  font-weight: 800;
}

.home-sub-heading {
  font-family: sans-serif;
}

.form label {
  font-weight: 500;
  font-size: 0.8em;
}

.form input,
.form select {
  margin: 10px 0;
  width: 20em;
  background: #3e5f64;
  color: white;
  border: none;
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
}

.form input:focus,
.form select:focus {
  outline: none;
}

.form-title {
  text-align: center;
  padding: 20px 0;
  font-size: 2em;
  font-weight: 600;
}

#form-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.submit-btn {
  margin: 10px 0;
  background-color: #2b7885;
  border: none;
  color: white;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.8em;
  border-radius: 40px;
}

.submit-btn:hover {
  cursor: pointer;
  background-color: #8bdbe9;
  color: black;
}
